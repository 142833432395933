
/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref, nextTick } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import pageData from '@/pageData/about'
import TImageDecorSplash from '@/components/TImageDecorSplash.vue'
import TImageDecorBrush from '@/components/TImageDecorBrush.vue'
import TImageDecorPortrait from '@/components/TImageDecorPortrait.vue'
import TImageDecorVideo from '@/components/TImageDecorVideo.vue'
import Tiger from '@/components/Tiger.vue'


export default defineComponent({
    props: {
        headImage: {
            default: "",
        },
        currTab:{default: ""}
    },
    components:{
        TImageDecorSplash, 
        TImageDecorBrush,
        TImageDecorPortrait, 
        TImageDecorVideo,
        Tiger
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        // const decorStyle = ref(2)
        const artworks = [
            'https://oss.huamei2001.com/hm2022/artworks/whiteframed1.png',
            'https://oss.huamei2001.com/hm2022/artworks/whiteframed4.png',
            'https://oss.huamei2001.com/hm2022/artworks/whiteframed2.png',
            'https://oss.huamei2001.com/hm2022-1/关于华美/whiteframed3.jpg?versionId=CAEQLhiBgIC5zumn9RciIDJhNjQ2ZTg5OWNmZDRkYTRhMTFjZjBkZTMxNjkwODNh',
            'https://oss.huamei2001.com/hm2022/artworks/whiteframed5.png',
        ]
        return {
            // decorStyle,
            pageData,
            scroll,
            scrollTo,
            idHash,
            artworks
        }
    }
});
